.InfoBox {
  position: relative;
  min-width: 200px;
  > span {
    border: 0;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    padding: 20px 14px 10px 14px;
    position: relative;
    z-index: 2;
  }
  > fieldset {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    border-color: rgba(0, 0, 0, 0.23);
    background-color: rgba(0, 0, 0, 0.02);    
    > legend > span {
      font-size: 0.75em;
      color: rgba(0, 0, 0, 0.80);
      background-color: #fff;
      border-radius: 4px;
    }
  }
}

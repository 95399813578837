.import-modal {
    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .import-data {
        width: 100%;
    }

    .import-data-table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;

        &.tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        th,
        tr,
        td {
            text-align: left;
            border: 1px solid #ddd;
            padding: 8px;
        }

        .not-valid {
            background: rgba(227 169 169 / 60%);
        }
    }

    .import-data-table tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .import-view {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .import-table-container {
        max-width: 1000px;
        width: 100%;
        overflow-y: auto;
    }

    .no-data-imported-container {
        margin-top: 20px;

        width: 100%;
        padding: 10px;
        text-align: center;
    }
}

.SplashScreen {
    width: 100vw;
    height: 100vh;
    background: #f3f3f3;
    padding-top: calc(50vh - 200px);
    .SplashContainer {
        max-width: 512px;
        margin: 0 auto;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
}

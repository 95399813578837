.order-evaluation-page {
    padding-top: 100px;
    background-color: #f3f3f3;
    min-height: 100vh;
    h1.page-title {
        text-align: center;
    }
    .login-logo {
        display: block;
        margin: 20px auto;
        max-width: 192px;
    }
    .order-evaluation-container {
        background-color: #fff;
        border-radius: 7px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        max-width: 600px;
        padding: 20px;
        margin: 0 auto;
    }
    .order-evaluation-form .MuiFormControl-root {
        width: 100%;
        margin: 10px 0;
    }
}

.info-container {
    padding: 15px;
    width: 100%;

    .info-container-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .actions {
            display: flex;
        }
    }

    &.full-width {
        width: 100%;
    }
}

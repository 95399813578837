.dashboard {
    .app-bar-container {
        position: relative;
    }
    .dashboard-content {
        padding-top: 20px;
        display: flex;        
        background-color: #f3f3f3;
    }

    .page-container {
        min-height: calc(100vh - 70px);
    }
}

.alerts-container {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    z-index: 10;

    .alert-container {
        display: flex;
        width: 100%;
        flex: 1;
        justify-content: center;
        margin-bottom: 5px;

        .alert {
            width: 100%;
            max-width: 800px;
        }

    }
}
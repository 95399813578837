.primary-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
   
    .value {
        display: flex;
        align-items: center;

        .actions {
            margin-left: 5px;
        }
    }

    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

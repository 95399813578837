.forgot-password-page {
    padding: 200px 20px;
    background-color: #f3f3f3;
    min-height: 100vh;
    .logo {
        display: block;
        margin: 0 auto;
        max-width: 512px;
    }
    .forgot-password-form-container {
        background-color: #fff;
        border-radius: 7px;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
        padding: 30px 20px;
        margin: 20px auto;
        max-width: 600px;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

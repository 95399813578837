.profile {
    width: 100%;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .header-content {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }

        .header-actions {
        }
    }
}
